<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter' size="mini">
    <el-form-item>
      <el-cascader
        v-model="filter.owner"
        @change="getData"
        placeholder="所属单位"
        :options="customerTree"
        :props="orgProps"
        clearable
        filterable />
    </el-form-item>
    <el-form-item>
      <el-radio-group v-model="filter.dtype" @change="handleType">
        <el-radio :label="1">监控设备</el-radio>
        <el-radio :label="2">监测设备</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type='primary' @click='getData'>刷新</el-button>
    </el-form-item>
  </el-form>
  </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-tabs v-model="curPane" @tab-click="handlePaneClick">
        <el-tab-pane label="待处理" name="1">
          <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' :max-height='clientHeight' style='width: 100%'>
            <el-table-column type='index' label='序号' align='center' width='55' />
            <el-table-column prop='MN' label='设备编号' align='center' width='80' header-align='center' />
            <el-table-column prop='Owner' :formatter="customerFormatter" label='所属单位' show-overflow-tooltip header-align='center' />
            <el-table-column prop='Addr' label='安装地址' show-overflow-tooltip header-align='center' />
            <el-table-column prop='AcquitAt' :formatter="dateFormat" label='报警日期' width='88' align='center' header-align='center' />
            <el-table-column label='原因及处理结果' align='center' header-align='center'>
              <template slot-scope="scope">
                <el-input v-if="scope.row.editable" class="input-box" size="mini" v-model="scope.row.Solution" />
              </template>
            </el-table-column>
            <el-table-column label='处理' align='center' header-align='center' width='80'>
              <template slot-scope='scope'>
                <i @click='handleProcess(scope.$index,scope.row)' :class="scope.row.editable?'opt fa fa-floppy-o':'opt el-icon-edit'" />
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已处理" name="2">
          <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' :max-height='clientHeight' style='width: 100%'>
            <el-table-column type='index' label='序号' align='center' width='55' />
            <el-table-column prop='MN' label='设备编号' align='center' width='80' header-align='center' />
            <el-table-column prop='Owner' :formatter="customerFormatter" label='所属单位' show-overflow-tooltip header-align='center' />
            <el-table-column prop='Addr' label='安装地址' show-overflow-tooltip header-align='center' />
            <el-table-column prop='AcquitAt' :formatter="dateFormat" label='报警日期' width='88' align='center' header-align='center' />
            <el-table-column prop='ProcessAt' :formatter="dateFormat" label='处理日期' width='88' align='center' header-align='center' />
            <el-table-column prop='Processor' :formatter="userFormatter" label='处理人' align='center' header-align='center' />
            <el-table-column prop='Solution' label='原因及处理结果' align='center' header-align='center' />
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-pagination small background @size-change="(v)=>handlePageChange(v, 'size', true)" @current-change="(v)=>handlePageChange(v, 'page', true)" :current-page.sync='filter.page' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total' style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { dateFormater, dataFormatter, join, getPname } from '@/util/index'

export default {
  data() {
    return {
      loading: false,
      datalist: {},
      mn: null,
      curPane: '1',
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      filter: {
        name: null,
        typ: 1,
        dtype: 2,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      }
    }
  },
  computed: {
    ...mapState({
      orgProps: state => Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
    }),
    ...mapState(['props', 'clientHeight', 'customer', 'customerTree', 'user', 'locale'])
  },
  created() {
    this.getData()
  },
  methods: {
    handleType() {
      this.filter.page = 1
      this.getData()
    },
    handlePaneClick() {
      if (this.curPane === '1') {
        this.filter.typ = 1
      } else {
        this.filter.typ = 2
      }
      this.getData()
    },
    handleProcess(i, row) {
      if (row.editable) {
        this.$post('admin/alarmProcess', { id: row.Id, solution: row.Solution })
        this.getData()
      }
      this.$set(this.datalist.content, i, Object.assign(row, { editable: !row.editable }))
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], false)
    },
    customerFormatter(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    getData: function() {
      this.loading = true
      var para = { StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size, Typ: this.filter.typ }
      para.Param = { DType: this.filter.dtype }
      if (this.filter.owner) {
        para.Param['Owner'] = join(this.filter.owner)
      }
      this.$post('admin/listAlarmMessage', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    }
  }
}
</script>
